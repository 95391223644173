<template>
  <div v-if="person">
    <top-decorations></top-decorations>
    <div class="container">
      <div class="backlink bookmark-container">
        <a class="bookmark" :href="role.url" v-for="(role, index) of roles" :key="index">
          {{ role.label }}
        </a>
      </div>
      <div class="bio row">
        <div class="col-lg-6">
          <h1>{{ person.field_first_name }} {{ person.field_person_last_name }}</h1>
          <p>{{ person.field_position }}</p>
          <div v-if="academicYears.length > 0" class="academic-years">
            Academic years:
            <span v-for="(year, index) of academicYears" :key="index">{{
              year.attributes.name
            }}</span>
          </div>
          <p v-if="person.field_person_email" class="person-email">
            <img src="@/assets/icon-mail-grey.svg" alt="email" />
            <a :href="`mailto:${person.field_person_email}`">{{ person.field_person_email }}</a>
          </p>
          <ul class="person-urls">
            <li v-if="person.field_person_personal_website">
              <a target="_blank" :href="person.field_person_personal_website.uri">
                <img src="@/assets/icon-website.svg" width="19" alt="website" />
                <a :href="person.field_person_personal_website.uri " target="_blank">Go to Personal Website</a>
              </a>
            </li>
            <li v-if="person.field_linkedin_profile">
              <a target="_blank" :href="person.field_linkedin_profile.uri"
                ><img src="@/assets/icon-linkedin-grey.svg" alt="email" />
                Linkedin Profile
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <div v-if="person.field_shared_description" class="short-bio">
            <h5>MORE INFO</h5>
            <div v-html="person.field_shared_description.processed"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-section">
      <div class="container">
        <div class="interests row">
          <div class="col-lg-6" >
            <div v-if="courses.length>0">
              <h4><img class="icon-lecturer" src="@/assets/icon-lecturer.svg" alt="lecturer" />Teaches modules</h4>
              <div class="course-part mb-lg-4" v-if="coursesPart1.length>0">
                <div class="course-part--label">PART ONE</div>
                <div  class="course-link" v-for="(course,index) of coursesPart1" :key="index">
                  <div class="course-link--title">{{course.attributes.title}}</div>
                </div>
              </div>
              <div class="course-part" v-if="coursesPart2.length>0">
                <div class="course-part--label">PART TWO</div>
                <div class="course-link" v-for="(course,index) of coursesPart2" :key="index">
                  <div class="course-link--title">{{course.attributes.title}}</div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-6">
            <div v-if="person.field_research_interest">
              <h4>Research interests</h4>
              <p>{{ person.field_research_interest }}</p>
            </div>
            <div v-if="person.field_research_group">
              <h4>Research groups</h4>
              <p>{{ person.field_research_group }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-else></div>
</template>
<script>
import TopDecorations from '@/components/TopDecorations.vue';

export default {
  name: "person-page",
  components: {TopDecorations},
  computed: {
    coursesPart1(){
      return this.courses.filter((course)=>{
        return course.attributes.field_part === "1"
      })
    },
    coursesPart2(){
      return this.courses.filter((course)=>{
        return course.attributes.field_part === "2"
      })

    },
    personNID() {
      return this.$route.params.nid.split('-').slice('-1')[0];
    },
    roles() {
      let roles = [];
      if (this.person.field_person_role) {
        roles = this.person.field_person_role.map((role) => {
          var ret = {};
          if (this.rolesLabels[role]) {
            ret.label = this.rolesLabels[role].label;
            ret.url = this.rolesLabels[role].url;
          }
          return ret;
        });
      }

      return roles;
    },
  },
  data() {
    return {
      person: null,
      academicYears: [],
      courses: [],
      rolesLabels: {
        council: {
          label: "Scientific Council",
          url: "/scientific-council",
        },
        lecturer: {
          label: "Lecturer",
          url: "/lecturers",
        },
        staff: {
          label: "Staff",
          url: "/staff",
        },
      },
    };
  },
  methods: {
    fetchPersonData() {
      if (this.personNID) {
        fetch(
          `${process.env.VUE_APP_ENDPOINT}/jsonapi/node/person/?filter[drupal_internal__nid]=${this.personNID}`
        )
          .then((res) => {
            return res.json();
          })
          .then((response) => {
            let personNode = response.data[0];
            this.person = personNode.attributes;
            this.fetchPersonCourses(personNode.id);
            this.fetchAcademicYears(
              personNode.relationships.field_academic_year.data
            );
          });
      }
    },
    fetchPersonCourses(personID){
      fetch(
        `${process.env.VUE_APP_ENDPOINT}/jsonapi/node/course?filter[field_course_lecturer.id]=${personID}`
      ).then((res=>res.json()))
      .then((response=>{
        this.courses = response.data;
      }))
    },
    fetchAcademicYears(academicYears) {
      if (academicYears.length > 0) {
        academicYears.forEach((year) => {
          fetch(
            `${process.env.VUE_APP_ENDPOINT}/jsonapi/taxonomy_term/academic_year/${year.id}`
          )
            .then((res) => res.json())
            .then((response) => {
              this.academicYears.push(response.data);
            });
        });
      }
    },
  },
  mounted() {
    this.fetchPersonData();
  },
};
</script>
<style lang="scss" scoped>
.academic-years {
  color: #1dc07e;
  text-transform: uppercase;
  span {
    color: #011b4a;
    font-weight: 500;
  }
}
.person-email {
  margin-top: 15px;
  margin-bottom: 20px;
  color: #ACACAC;
  display: flex;
  a{
    color: #ACACAC;
  }
  img {
    margin-right: 5px;
    width: 20px;
  }
}
.person-urls {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  flex-wrap: wrap;
  font-weight: 100;
  li {
    margin-right: 5px;
    list-style: none;
    margin-bottom: 10px;
    a {
      display: inline-flex;
      align-items: center;
      color: #acacac;
      img {
        margin-right: 5px;
        width: 20px;
      }
    }
  }
}
.short-bio {
  h5 {
    position: relative;
    padding-left: 25px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 27px;
    &:before {
      content: "";
      left: 0;
      background: url("../assets/list-point-green.png");
      width: 19px;
      height: 28px;
      background-size: cover;
      position: absolute;
    }
  }
}
.backlink a{
  display: none;
}
.bottom-section{
  background: #f8f8f8;
  padding-top: 40px;
  margin-top: 40px;
  padding-bottom: 80px;
}
.icon-lecturer{
  opacity: 0.3;
  width: 29px;
  margin-right: 20px;
}

.interests h4{
  color: #1DC07E;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 16px;
}

.course-part{
  border: 2px solid #1DC07E;
  border-radius: 29px;
  .course-part--label{
    color: #ACACAC;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &::v-deep p {
    margin: 0;
  }
}
.course-link{
  display: flex;
  color: #293446;
  align-items: center;
  justify-content: space-between;
}
@media (min-width:992px) {
  .course-part{
    margin-right: 60px;
    padding: 30px 30px 30px 40px;
  }
  .backlink{
    a {
      display: inline-flex;
    }
  }
}
</style>